<template>
  <el-container style="height:calc(100vh);">
    <el-header>
      <span class="left el-icon-arrow-left" @click="returnOn">优惠设置</span>
      抹零设置
    </el-header>
    <el-main class="main">
      <div class="content">
        <div class="store-set">
          <div class="cell-t">
            <div class="title">
              手动抹零
            </div>
            <div class="value">
              <el-switch v-model="switchValue1" active-color="#409eff">
              </el-switch>
            </div>
          </div>
          <div class="box">
            <div class="cell-t">
              <div class="title">
                自动抹零
              </div>
              <div class="value">
                <el-switch v-model="switchValue2" active-color="#409eff">
                </el-switch>
              </div>
            </div>
            <div class="radio">
              <el-radio-group v-model="malingRadio">
                <el-radio label="抹分">
                  <div class="box-radio">
                    <div class="title">抹分</div>
                    <div class="label">向下抹分，如9.99元，则实收9.9元</div>
                  </div>
                </el-radio>
                <el-radio label="抹角">
                  <div class="box-radio">
                    <div class="title">抹角</div>
                    <div class="label">向下抹角，如9.9元，则实收9元</div>
                  </div>
                </el-radio>
                <el-radio label="四舍'分'">
                  <div class="box-radio">
                    <div class="title">四舍'分'</div>
                    <div class="label">如9.94元，则实收9.9元</div>
                  </div>
                </el-radio>
                <el-radio label="五入到'角'">
                  <div class="box-radio">
                    <div class="title">五入到'角'</div>
                    <div class="label">如9.99元，则实收10.00元</div>
                  </div>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        switchValue1: true,
        switchValue2: true,
        malingRadio: '抹分'
      }
    },
    created() {},
    methods: {
      // 返回上级
      returnOn() {
        this.$router.push({
          name: 'PreferentialSet'
        })
      },
    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    position: relative;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      display: flex;
      align-items: center;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      color: #666;
      line-height: 19px;

      &::before {
        color: #46a6ff;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }

  .main {
    background-color: #f9f9f9;
  }

  .content {
    padding: 20px;

    .store-set {
      .cell-t {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 2px #ccc;
        padding: 10px 20px;
        background-color: #fff;
        margin-bottom: 15px;

        .title {
          line-height: 24px;
        }

      }

      .box {
        box-shadow: 0 0 2px #ccc;
        padding: 10px 20px;
        background-color: #fff;
        margin-bottom: 15px;

        .cell-t {
          padding: 0;
          padding-bottom: 10px;
          box-shadow: 0 0 0;
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 0;


        }
      }

    }
  }

  /deep/ .el-radio-group {
    width: 100%;

    .el-radio {
      display: flex;
      align-items: center;
      line-height: 24px;
      padding: 5px;
      border-bottom: 1px solid #f2f2f2;

      .title {
        color: #666;
      }

      .label {
        font-size: 12px;
        color: #999;
      }

    }
  }

</style>
